









import { Component } from 'vue-property-decorator'

import { MarkdownEditor } from '../../../../molecules/MarkdownEditor'

import { AbstractAddon } from '../AbstractAddon.form'

import { TextAddon } from './Text.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 */
@Component<TextAddonForm>({
  name: 'TextAddonForm',
  components: { MarkdownEditor }
})
export class TextAddonForm extends AbstractAddon {
  public onTextUpdate (content: string): void {
    this._item = {
      ...this._item,
      content
    } as TextAddon
  }
}
export default TextAddonForm
